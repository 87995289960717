import { Component } from '@angular/core';

@Component({
  selector: 'una-spa-mc-payment-control-ng',
  template: ` <router-outlet> </router-outlet> `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'una-spa-mc-payment-control-ng';
}
